<script lang="ts" setup>
import { useIntervalFn } from '@vueuse/core'
import { useAuth } from '~/store/auth'

interface Props {
  recipient: string
  type: string
  channel: string
  timeout: number
}

const props = defineProps<Props>()
const authStore = useAuth()
const isLoading = ref(false)
const isSuccess = ref(false)
const errorVal = ref(null)
const timer = ref(props.timeout)
const { pause, resume, isActive } = useIntervalFn(() => {
  if (timer.value <= 1) {
    errorVal.value = null
    pause()
  }
  timer.value--
}, 1000)
const errorList = [
  { id: 'ERR_NOT_FOUND', value: 'No account associated with this email' },
  { id: 'ERR_WITHIN_COOLDOWN_PERIOD', value: 'Please wait a few minutes and try again' },
  { id: 'ERR_MAX_REQUEST_EXCEEDED', value: 'You are exceeding daily login attempt' },
]

async function onResendOTP() {
  if (!isActive.value) {
    isLoading.value = true
    errorVal.value = null
    const otpRes = await authStore.sendOTP(props.recipient, props.type, props.channel)
    if (otpRes.status) {
      isSuccess.value = true
      timer.value = props.timeout
    }
    else {
      errorVal.value = otpRes.msg
      isSuccess.value = false
    }
    isLoading.value = false
    await useSleep(5000)
    isSuccess.value = false
    resume()
  }
}
</script>

<template>
  <div class="text-gray-600 text-xs">
    <div v-if="!isSuccess && errorVal" class="flex items-center">
      <Icon name="heroicons:exclamation-triangle" class="w-5 h-5 mr-1 text-rose-500" /> {{ errorList.find(val => val.id === errorVal)?.value }}
    </div>
    <div v-else-if="isSuccess" class="flex items-center text-gray-500">
      <Icon name="heroicons:check-circle-20-solid" class="w-5 h-5 mr-1 text-green-500" /> New OTP code sent
    </div>
    <div v-if="!isSuccess && !isLoading">
      Didn’t get a code?
      <label :class="`${isActive ? '' : 'text-indigo-500 cursor-pointer'} font-bold`" @click="onResendOTP()">{{ !isActive ? 'Resend OTP' : 'Resend in' }} </label>
      <span v-if="isActive" class="font-bold ml-1"> {{ timer }}s </span>
    </div>
  </div>
</template>
